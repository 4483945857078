<template>
  <div style="height: 250px; width: 100%; z-index: 0; position: relative;">
    <l-map
      ref="map"
      :options="mapOptions"
      :zoom="zoom"
      :center="currentCenter"
      @update:zoom="zoomUpdate"
      @update:center="centerUpdate"
      @ready="setMap"
      style="height: 100%"
    >
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-marker :lat-lng="currentCenter">
        <l-icon :icon-size="[36, 36]">
          <div
            class="secondary pin"
            :style="`border: 2px solid ${color} !important;`"
          >
            <v-icon class="icon-position" :color="color">
              {{ icon }}
            </v-icon>
          </div>
        </l-icon>
      </l-marker>
    </l-map>
    <unclickable right bottom />
  </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import Unclickable from "@/common/components/Unclickable";

export default {
  name: "PinLocationMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    Unclickable,
  },
  model: {
    prop: "marker",
    event: "update",
  },
  props: {
    color: String,
    icon: String,
    marker: Object,
    geofence: Array,
  },
  data() {
    return {
      zoom: 15,
      maxBounds: L.latLngBounds([
        [12.72, 44.8],
        [12.81, 44.95],
      ]),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      attribution: "&copy; OSM",
      currentZoom: 11.5,
      mapOptions: {
        attributionControl: false,
        zoomSnap: 0.5,
        minZoom: 12,
        maxZoom: 18,
      },
      center: null,
      currentCenter: this.getPoint(this.marker),
    };
  },
  methods: {
    getPoint(m) {
      return L.latLng(m.latitude, m.longitude);
    },
    async panToCurrent() {
      this.$currentPosition().then((pos) => {
        const { coords } = pos;
        const { latitude, longitude } = coords;
        this.currentCenter = this.getPoint({ latitude, longitude });
      });
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center || this.getPoint(this.marker);
      this.$emit("update", {
        latitude: this.coordsRound(this.currentCenter.lat),
        longitude: this.coordsRound(this.currentCenter.lng),
      });
    },
    async setMap() {
      await this.$nextTick();

      this.map = this.$refs.map.mapObject;
      if (this.marker) {
        var bound = L.latLngBounds([this.getPoint(this.marker)]);
        this.map.fitBounds(bound, { padding: L.point(30, 30) });
      }

      const clickHandler = this.panToCurrent;
      var PanToCurrentButton = L.Control.extend({
        options: {
          position: "topleft",
        },

        onAdd: function(map) {
          var container = L.DomUtil.create(
            "a",
            "leaflet-bar leaflet-control leaflet-control-custom v-icon notranslate mdi mdi-target-variant theme--light"
          );

          container.style.backgroundColor = "white";
          container.style.backgroundSize = "34px 34px";
          container.style.width = "34px";
          container.style.height = "34px";

          container.onclick = clickHandler;

          return container;
        },
      });
      this.map.addControl(new PanToCurrentButton());

      setTimeout(() => {
        this.map.invalidateSize();
      }, 200);
    },
  },
};
</script>

<style>
.pin {
  position: absolute;
  border-radius: 50% 50% 50% 0;
  border: 2px solid #fff;
  width: 32px;
  height: 32px;
  transform: rotate(-45deg);
  margin-top: -16px;
}

.pin .icon-position {
  transform: rotate(45deg);
  margin-top: 1px;
  margin-left: 3px;
}
</style>
